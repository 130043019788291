import ApiService from "../base/api.service";
import {Boostpack} from "@/services/boostpack/boostpack.type";
import {Category} from "@/services/boostpack/category/category.type";
import {BoostpackFactory} from "@/services/boostpack/boostpack_factory.type";

const resource = '/category';

/* eslint-disable */
class ResponseError extends Error {
	errorCode: any;
	errorMessage: any;
	constructor(errorCode: any, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		this.errorCode = errorCode;
	}
}

const CategoryService = {
	get() {
		try {
			//TODO pagination interface
			return ApiService.get(`${resource}`);
		} catch (error) {
			throw new ResponseError(
				error.status,
				error.message
			);
		}
	},

	getCategories: async function () {
		try {
			let result = {};
			//TODO add language and load language
			const response = await ApiService.get(`${resource}`);
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const boostpackFactory = new BoostpackFactory();
					result = boostpackFactory.createCategoryListByResponse(responseData);
				}
			}
			return result;
		} catch (error) {
			if (error.response) {
				throw new ResponseError(
					error.response.status,
					error.response.error.message
				);
			} else if (error.request) {
				// The request was made but no response was received
				// Error details are stored in error.reqeust
				console.log(error.request);
			} else {
				// Some other errors
				console.log('Error', error.message);
			}
		}
	},

	async getCategoryById(id: number):  Promise<Category | null>
	{
		try {
			const response = await ApiService.get(`${resource}/${id}`);
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const boostpackFactory = new BoostpackFactory();
					return boostpackFactory.createCategoryByData(responseData);
				}
			}
			return null;
		} catch (error) {
			throw new ResponseError(
				error.status,
				error.message
			);
		}
	},

	create(payload: any) {
		return ApiService.post(`${resource}`, payload);
	},
	update(payload: any, id: number) {
		return ApiService.put(`${resource}/${id}`, payload);
	},
	delete(id: number) {
		return ApiService.delete(`${resource}/${id}`)
	},
}

export { CategoryService, ResponseError };