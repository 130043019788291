
import {defineComponent, ref, watch} from 'vue';

export default defineComponent({
	name: 'RedirectLabelList',
	components: {},
	props: {
		labelList: {
			type: Object,
			default: null,
		},
		activeLabels: {
			type: Object,
			default: null,
		},
		labelColor: {
			type: String,
			default: "indigo",
		},
		selectedLabelColor: {
			type: String,
			default: "indigo",
		},
		fontSize: {
			type: String,
			default: "text-sm",
		}
	},
	emits: ['deleteLabel', 'selectLabel'],
	setup(props, {emit}) {

		const labels = ref(props.labelList);
		const selectedLabels = ref(props.activeLabels);

		watch(() => props.labelList, (newValue, oldValue) => labels.value = newValue);
		watch(() => props.activeLabels, (newValue, oldValue) => selectedLabels.value = newValue);

		const deleteLabelButton = (label) => {
			const deletedLabelIndex = selectedLabels.value.findIndex(function (o) {
				return o.id === label.id;
			})
			const deletedLabel = selectedLabels.value.find(element => element.id === label.id);
			selectedLabels.value.splice(deletedLabelIndex, 1);
			labels.value.push(deletedLabel);
			emit('deleteLabel', selectedLabels.value, labels.value);
		};

		const selectLabel = (label) => {
			const labelIndex = labels.value.findIndex(function (o) {
				return o.id === label.id;
			})
			const labelFound = labels.value.find(element => element.id === label.id);
			labels.value.splice(labelIndex, 1);
			selectedLabels.value.push(labelFound);
			emit('selectLabel', selectedLabels.value, labels.value);
		};
		return {deleteLabelButton, selectLabel, labels, selectedLabels};
	}
})
