
import {defineComponent, onMounted} from 'vue';
import DefaultCardLayout from "@/components/Card/DefaultCardLayout.vue";

export default defineComponent({
	name: 'DefaultMediaNetAd',
	components: {},
	props: {
		title: {},
	},
	setup() {

		function mediaNetAdLoad() {
			const inlineScript = document.createElement("script");
			inlineScript.type = "text/javascript";
			inlineScript.text = '\t\ttry {\n' +
				'\t\t\twindow._mNHandle.queue.push(function (){\n' +
				'\t\t\t\twindow._mNDetails.loadTag("642342141", "300x250", "642342141");\n' +
				'\t\t\t});\n' +
				'\t\t}\n' +
				'\t\tcatch (error) {}';
			const adDiv = document.getElementById('642342141');
			if (adDiv) {
				adDiv.appendChild(inlineScript);
			}
		}

		onMounted(() => {
			mediaNetAdLoad();
		})

		return {};
	}
})
