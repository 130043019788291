
import {defineComponent, onMounted, ref} from 'vue'
import {useRoute} from "vue-router";
import DefaultBoostpackCard from "@/components/Card/Boostpack/DefaultBoostpackCard.vue";
import {BoostpackService} from "@/services/api/boostpack/boostpack.service";
import useBoostpackItemRepositories from "@/composable/boostpack/useBoostpackItemRepositories";
import useCustomStore from "@/composable/custom/useCustomStore";
import DefaultSpinner from "@/components/Loading/DefaultSpinner.vue";
import DefaultMediaNetAd from "@/components/Ads/MediaNet/DefaultMediaNetAd.vue";
import RedirectLabelList from "@/components/Label/RedirectLabelList.vue";
import {CategoryService} from "@/services/api/category/category.service";
import {Category} from "@/services/boostpack/category/category.type";
import router from "@/router";

export default defineComponent({
	name: 'BoostpackCategoryFeed',
	components: {
		RedirectLabelList,
		DefaultMediaNetAd,
		DefaultSpinner,
		DefaultBoostpackCard
	},
	props: {},
	setup() {
		const {initBoostpacks, getBoostpacks} = useBoostpackItemRepositories();
		const {getPageReloaded, setPageReloaded} = useCustomStore();
		const pageReloaded = ref(getPageReloaded);
		const boostpacks = ref(getBoostpacks);
		const boostpackFeedLoading = ref(false);
		const categoryList = ref();
		const categoryListForLabel = ref();
		const boostSelectedCategories = ref();

		const getBoostpackItems = async () => {
			initBoostpacks(await BoostpackService.getBoostpackFeedWithParameter(), true);
			boostpackFeedLoading.value = false;
		}

		const getBoostpackItemsByCategories = async (params) => {
			initBoostpacks(await BoostpackService.getBoostpackFeedWithParameter(params), true);
			boostpackFeedLoading.value = false;
		}

		async function loadCategories(activeCategoryList: Array<any> = []) {
			categoryList.value = await CategoryService.getCategories();
			let key: any;
			let category: any|Category;
			const arrData: Array<any> = [];
			const arrSelectedCategories: Array<any> = [];
			for ([key, category]  of Object.entries(categoryList.value)) {
				const newCategoryForLabel: Record<string, any> = {
					id: category.id,
					name: category.categoryName,
				};
				if (activeCategoryList && activeCategoryList.includes(category.categoryName)) {
					arrSelectedCategories.push(newCategoryForLabel);
				} else {
					arrData.push(newCategoryForLabel);
				}
			}
			boostSelectedCategories.value = arrSelectedCategories;
			categoryListForLabel.value = arrData;
		}

		onMounted(() => {
			if (pageReloaded.value || !boostpacks.value.length) {
				boostpackFeedLoading.value = true;
			}
			let boostpackCategory;
			boostpackCategory = useRoute().params.category;
			if (boostpackCategory) {
				boostpackCategory = String(boostpackCategory).trim();
				const boostpackCategoryForRequest = boostpackCategory.replaceAll(' ', '_');
				loadCategories(boostpackCategory.split(","));
				getBoostpackItemsByCategories({categories: boostpackCategoryForRequest});
			} else {
				loadCategories();
				boostSelectedCategories.value = [];
				if (pageReloaded.value) {
					getBoostpackItems();
					setPageReloaded(false);
				} else if (!boostpacks.value || !boostpacks.value.length) {
					getBoostpackItems();
				}
			}
			// boostpackFeedLoading.value = false;
		})

		/**
		 *
		 */
		function isBoostpackFeedLoading(): boolean {
			return boostpackFeedLoading.value === true;
		}

		function updateAndRedirect(selectedLabels, labels){
			let redirectLink = "/categories";
			if (selectedLabels && selectedLabels.length > 0){
				const arrCategories: Array<any> = [];
				let key: any;
				let category: any;
				for ([key, category]  of Object.entries(selectedLabels)) {
					arrCategories.push([category.name]);
				}
				redirectLink += "/" + arrCategories.join(",");

				const categoriesForRequest = arrCategories.join(",").trim().replaceAll(' ', '_');
				console.log(categoriesForRequest)
				// loadCategories(arrCategories);
				getBoostpackItemsByCategories({categories: categoriesForRequest});
			} else {
				boostpackFeedLoading.value = true;
				loadCategories();
				boostSelectedCategories.value = [];
				getBoostpackItems();
			}
			router.push(redirectLink);
		}

		window.addEventListener('beforeunload', function () {
			setPageReloaded(true);
		})

		return {boostpacks, getBoostpackItems, boostpackFeedLoading, isBoostpackFeedLoading, categoryList, boostSelectedCategories, categoryListForLabel, updateAndRedirect}
	},
})
